import React, { useState, useEffect, useCallback } from 'react';
//import data from '../database/list.json';
import data from '../database/audiolistss.json';
// import ProgressBar from './progress';

function AudioList({ onAudioSelect, onFileName, audioProgress }) {


  const [audioData, setAudioData] = useState(data);
  const [filter, setFilter] = useState('');

  const handleInputChange = (e) => {
    setFilter(e.target.value.toUpperCase());
  };

  const handleAudioSelect = (filepath, filename) => {
    const updatedAudioData = audioData.map((audio) =>
      audio.filepath === filepath ? { ...audio, progress: 100 } : audio
    );
    setAudioData(updatedAudioData);
    onAudioSelect(filepath);
    onFileName(filename);
  };

  const filterTableRows = useCallback(() => {
    const table = document.getElementById("myDataTable");
    const rows = table.getElementsByTagName("tr");

    for (let i = 1; i < rows.length; i++) {
      const td = rows[i].getElementsByTagName("td")[1];
      if (td) {
        const txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          rows[i].style.display = "";
        } else {
          rows[i].style.display = "none";
        }
      }
    }
  }, [filter]);

  useEffect(() => {
    filterTableRows();
  }, [filter, filterTableRows]);

  return (
    <div className="mt-3">
      <div className="row">
        <div className="col-12">
          <div className="input-group">
            <input
              onKeyUp={handleInputChange}
              type="text"
              id="myInput"
              className="form-control mb-1"
              placeholder="Search with surah name or number.."
            />
            <span className="input-group-text">
              <i className="bi bi-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div className="table-responsive audio-list" style={{ height: 300 }}>
        <table className="table table-sm table-success" id="myDataTable">
          <thead className="sticky-top">
            <tr>
              <th className="text-center">S No</th>
              <th>Surah Name</th>
              <th>Play</th>
            </tr>
          </thead>
          <tbody>
            {audioData.map((audio, index) => (
              <tr key={audio.id}>
                <td className="text-center">
                  <button
                    className="btn p-0 font-sm"
                    onClick={() => {
                      handleAudioSelect(audio.filepath, audio.filename);
                    }}
                  >
                    {audio.id}
                  </button>
                </td>
                <td>
                  <button
                    className="btn p-0 font-sm w-100 text-start"
                    onClick={() => {
                      handleAudioSelect(audio.filepath, audio.filename);
                    
                    }}
                  >
                    {audio.filename}
                  </button>
                </td>
                {/*<td>
                   <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${audio.progress || 0}%` }}
                      aria-valuenow={audio.progress || 0}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div> 
                  {/* <ProgressBar />
                </td>*/}
                <td>
                  <button
                    className="btn p-0 font-md"
                    onClick={() => {
                      handleAudioSelect(audio.filepath, audio.filename);
                    }}
                  >
                    <i className="bi bi-play-fill"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AudioList;