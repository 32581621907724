import React from "react";
//import { Button } from "reactstrap";
//import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./Footer";

function DefaultHome() {
  // const {
  //   loginWithRedirect,
  // } = useAuth0();


  return (
    <>

      <div className=" text-center">
        <h1 className="mb-4 arabic-font-1">بسم الله الرحمن الرحيم</h1>
        {/* <h6>Dars-E-Quraan</h6>
        <p className="urdu-font-1 font-lg">
          مفسر قرآن حافظ وقاری
          مولانا محمد عامر خان قاسمي
        </p> */}
        <div class="container mt-4">
            <div class="card text-center">
                <div class="card-body">
                    <h5 class="card-title">Important Update</h5>
                    <p class="card-text">
                        Thank you for your interest in Dars-e-Quraan. Our app is currently unavailable, but we will be back shortly. Please stay tuned for updates. We apologize for any inconvenience and appreciate your patience.
                    </p>
                    <p class="card-text text-bg-warning rounded p-1">
                        Note: For users with a valid subscription, we have emailed the download link for the content to your subscription email id. This link will be available until November 30, 2024. Thank you for your continued support
                    </p>
                </div>
            </div>
        </div>

        {/* <Button
          className="w-25 btn btn-secondary"
          id="qsLoginBtn"
          color="primary"
          onClick={() => loginWithRedirect()}
          size="sm"
          style={{ marginBottom: '5%' }}
        >
          Log in
        </Button> */}

        <Footer />


      </div>

    </>
  );
}

export default DefaultHome;
