import React, { useState } from "react";
import axios from "axios";
import { Button } from "reactstrap";
//import AuthorizedContent from "./AuthorizedContent";

function SuccessPage() {
  const [data, setData] = useState('');

  console.log(data);

  const triggerNodeRoute = async () => {
    try {
      const response = await axios.patch('http://localhost:3001/api/patch', {
        key: 'value',
        // Add any data you want to send to the server
      });
      
      setData(response.data.message);
    } catch (error) {
      console.error('Error making request:', error);
    }
  };

    return(<>
        <div className="mt-5">
            <h6 className="text-center">You have successfully completed</h6>
            <Button
          color="primary"
          className="mt-5"
          onClick={triggerNodeRoute}
          to="/authorize"
        >
          Back to Home
        </Button>
        </div>
    </>);
};

export default SuccessPage;