import React from "react";
import Footer from "../components/Footer";

function Refund() {
    return (<>
        <div className="compalinace-page">
            <div className="compalinace-content">
                <div className="content-container">
                    <p className="content-head fs-3 fw-bold">No Refund Policy </p>
                    <div className="content-seprater"></div>
                    <p className="updated-date"><strong>Effective Date:</strong> Nov 5th 2023</p>
                    <div>
                  


   
    <p>Thank you for using Jamia Safiya Arbia Lilbanath's online platform Dar-Ul-Kitab. We value your business and trust in our products and services. Please read this No Refund Policy carefully before making a purchase. By making a purchase on our website, you acknowledge and agree to the following terms:</p>

    <ol>
        <li>
            <strong>All Sales Are Final:</strong>
            <ul>
                <li>We do not offer refunds or exchanges for any of our products or services unless explicitly stated otherwise in a separate agreement or policy.</li>
                <li>This policy applies to all purchases made through our website, in-store, or any other sales channel.</li>
            </ul>
        </li>
        
        <li>
            <strong>Cancellation of Services:</strong>
            <ul>
                <li>If you have purchased a service that is cancellable or subject to specific terms, please refer to the respective service agreement or terms for cancellation and refund details.</li>
            </ul>
        </li>
        <li>
            <strong>Contact Information:</strong>
            <ul>
                <li>If you believe you qualify for an exemption or have questions regarding our refund policy, please contact us at:</li>
                <li>Address: 8-1-398/PM/207, Paramount Colony, Gate No 3, Opp IPS School, Tolichowki, Hyderabad 500008.</li>
                <li>Mobile No: +919885687670</li>
                <li>Email: <a href="mailto:amirquasmi@gmail.com">amirquasmi@gmail.com</a></li>
            </ul>
        </li>
        <li>
            <strong>Policy Changes:</strong>
            <ul>
                <li>We reserve the right to amend or update this No Refund Policy at our discretion. Any changes will be effective immediately upon posting the updated policy on our website.</li>
            </ul>
        </li>
    </ol>

    <p>By making a purchase, you acknowledge that you have read and understand our No Refund Policy and agree to be bound by its terms.</p>



                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>);
};

export default Refund;