// import React from "react";
// import { NavLink as RouterNavLink } from "react-router-dom";
// import { Nav, NavItem, NavLink } from "reactstrap";

// const date = new Date();
// const year = date.getFullYear();
// console.log(year);

// const Footer = () => (
//   <footer className="bg-white text-center fixed-bottom py-2" style={{ minHeight: '10px', zIndex: '-1' }}>
//     {/* <div className="logo" /> */}

//     <div className="row">
//       <div className="col-sm-3">
//       {/* <a className="btn btn-custom-primary btn-sm w-75" href="/About">About Us</a> */}
//         <Nav>
//           <NavItem>
//             <NavLink
//               tag={RouterNavLink}
//               to="/about"
//               exact
//               activeClassName="router-link-exact-active"
//               style={{textAlign: 'center'}}
//             >
//               About Us
//             </NavLink>
//           </NavItem>
//         </Nav>
//       </div>
//       <div className="col-sm-3">
//         {/* <a className="btn btn-custom-primary btn-sm w-75" href="/Terms">Terms and Conditions</a> */}
//         <Nav>
//           <NavItem>
//             <NavLink
//               tag={RouterNavLink}
//               to="/terms"
//             >
//               Terms and Conditions
//             </NavLink>
//           </NavItem>
//         </Nav>
//       </div>
//       <div className="col-sm-3">
//       {/* <a className="btn btn-custom-primary btn-sm w-75" href="/Privacy">Privacy Policy</a> */}
//       <Nav>
//           <NavItem>
//             <NavLink
//               tag={RouterNavLink}
//               to="/privacy"
//               exact
//               activeClassName="router-link-exact-active"
//             >
//               Privacy Policy
//             </NavLink>
//           </NavItem>
//         </Nav>
//       </div>
//       <div className="col-sm-3">
//       {/* <a className="btn btn-custom-primary btn-sm w-75" href="/Refund">Refund Policy</a> */}
//       <Nav>
//           <NavItem>
//             <NavLink
//               tag={RouterNavLink}
//               to="/refund"
//               exact
//               activeClassName="router-link-exact-active"
//             >
//               Refund Policy
//             </NavLink>
//           </NavItem>
//         </Nav>
//       </div>
//     </div>
//     <p className="mb-0 mt-3 font-sm">
//       {year} © Jamia Safiya Arbia Lilbanath. All rights reserved.
//     </p>
//   </footer>
// );

// export default Footer;


// FooterNavbar.js

import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';

const Footer = () => {

  const date = new Date();
  const year = date.getFullYear();
  //console.log(year);

  return (
    <div className="row fixed-bottom bg-white">
      <div className="col-sm-4">
        <p className="mb-0 ps-3 mt-2 font-sm">
          {year} © Jamia Safiya Arbia Lilbanath. All rights reserved.
        </p>
      </div>
      <div className="col-sm-8 font-sm">
        <div className='row'>
          <div className='col-lg-1 col-sm-6'>
            <Nav className="text-center">
              <NavItem>
                <NavLink tag={RouterNavLink} to="/about" style={{ textAlign: 'center' }} exact activeClassName="active">
                  About
                </NavLink>
              </NavItem>
              
            </Nav>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <Nav className="text-center">
              
              <NavItem>
                <NavLink tag={RouterNavLink} to="/terms" activeClassName="active">
                  Terms and Conditions
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className='col-lg-2 col-sm-6'>
            <Nav className="text-center">
              <NavItem>
                <NavLink tag={RouterNavLink} to="/privacy" activeClassName="active">
                  Privacy Policy
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className='col-lg-1 col-sm-6'>
            <Nav >
              <NavItem>
                <NavLink tag={RouterNavLink} to="/refund" style={{ textAlign: 'center' }}>
                  Refund 
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className='col-lg-1 col-sm-6'>
            <Nav >
              <NavItem>
                <NavLink tag={RouterNavLink} to="/contact" style={{ textAlign: 'center' }}>
                  Contact 
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <Nav >
              <NavItem>
                <NavLink tag={RouterNavLink} to="/shipping" style={{ textAlign: 'center' }}>
                  Shipping & Delivery 
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;