import React from "react";
import Footer from "../components/Footer";

function About() {
    return(<>
        <div className="px-4 text-center mt-3">

        <div className="col-sm-12">
            <p>
                Welcome to Dar-ul-Kitab, a sacred platform driven by the spirit of knowledge and devotion. We are a part
                of the esteemed trust, Jamia Safiya Arbia Lilbanath, dedicated to spreading the divine wisdom of the
                Quran. Our mission is to provide a holistic learning experience that transcends boundaries, reaching the
                hearts and minds of seekers both online and within the vibrant community of Hyderabad.
            </p>
            <h2>Our Vision</h2>
            <h6>Enlightening Minds, Transforming Lives</h6>
            <p>
                Dar-ul-Kitab is not just a website; it's a sanctuary for those in search of spiritual growth and
                understanding. We understand that, in today's fast-paced world, access to religious knowledge should be
                as seamless as possible. Thus, our online platform offers a wealth of Quranic lectures, discussions, and
                resources, all designed to deepen your connection with the Holy Quran from the comfort of your own
                space.
            </p>
            <p>
                In addition to our online presence, we also have a physical presence in local mosques across Hyderabad.
                We believe in the power of communal learning and offer offline Quran lectures and study sessions where
                you can interact with scholars and fellow seekers, enriching your understanding and fostering a sense of
                belonging within the local community.
            </p>
            <h2>Our Commitment</h2>
            <h6>Knowledge, Faith, Unity</h6>
            <p>
                At Dar-ul-Kitab, we are committed to fostering a spirit of unity through knowledge. We believe that the
                Quran is a source of wisdom, peace, and guidance for all, and we strive to make this wisdom accessible
                to people of all ages and backgrounds. Our dedicated team of scholars and educators work tirelessly to
                create enriching content that is both enlightening and empowering.
            </p>

            <p>
                Whether you are joining us online or in person, we invite you to explore our website, attend our
                classNamees, and engage in the beautiful journey of understanding the Quran. Dar-ul-Kitab is more than a
                place of learning; it's a community of faith, a sanctuary for seekers, and a guiding light towards a
                deeper connection with the divine.
            </p>
            <h6>
                Thank you for choosing Dar-ul-Kitab as your partner on this sacred quest. We look forward to joining you
                on this enriching journey of faith and knowledge.
            </h6>
        </div>
        <Footer />
        
    </div>
    </>);
};

export default About;