import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
//import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Terms from "./views/Terms";
import Privacy from "./views/Privacy";
import Shipping from "./views/Shipping";
import Subscribe from "./views/Subscribe";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import About from "./views/About";
import Contact from "./views/Contact";
import SuccessPage from "./views/SuccessPage";
import Refund from "./views/Refund";
import AuthorizedContent from "./views/AuthorizedContent";
//import Footer from "./components/Footer";
initFontAwesome();


const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app">
        <NavBar />
        <Container>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/about" component={About} />
            <Route path="/success" component={SuccessPage} />
            <Route path="/contact" component={Contact} />
            <Route path="/shipping" component={Shipping} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/refund" component={Refund} />
            <Route path="/subscribe" component={Subscribe} />
            <Route path="/authorize" component={AuthorizedContent} />
            <Route path="/external-api" component={ExternalApi} /> 
          </Switch>
        </Container>
      </div>
    </Router>
  );
};

export default App;
