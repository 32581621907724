import React from "react";
import Footer from '../components/Footer';
function Shipping() {
    return (<>
        <div className="compalinace-page mb-5">
            <div class="compalinace-content">
                <div class="content-container">
                    <h6 class="content-head"> <strong>Shipping &amp; Delivery Policy</strong> </h6>
                    <div class="content-seprater"></div>
                    <p class="updated-date">Last updated on Apr 16th 2024</p>


                    <p>Thank you for choosing <strong>Dar-ul-Kitab</strong>! Our goal is to provide you with a seamless and enjoyable experience while accessing your favorite music. Please review the following shipping and delivery policy:</p>

                    <ol>
                        <li><strong>Delivery Method:</strong>
                            <ul>
                                <li>As an online music streaming service, there are no physical shipments involved. Our service is entirely digital, allowing you instant access to our extensive music library upon subscription.</li>
                            </ul>
                        </li>

                        <li><strong>Subscription Activation:</strong>
                            <ul>
                                <li>Once you've completed the subscription process and payment, your access to <strong>Dar-ul-Kitab</strong> will be activated immediately. You can start streaming music instantly via our website or mobile app.</li>
                            </ul>
                        </li>

                        <li><strong>Accessible Platforms:</strong>
                            <ul>
                                <li><strong>Dar-ul-Kitab</strong> is accessible across multiple platforms, including iOS, Android, web browsers, smart TVs, and other compatible devices. You can enjoy your music seamlessly across all your devices with a single subscription.</li>
                            </ul>
                        </li>

                        <li><strong>Geographic Availability:</strong>
                            <ul>
                                <li>Our music streaming service is available globally to access and enjoy our extensive catalog.</li>
                            </ul>
                        </li>

                        <li><strong>Subscription Renewal:</strong>
                            <ul>
                                <li>Please note that the lifetime subscription provided is for a limited period, and our company reserves the full rights to modify this offering as necessary. Subscriptions to <strong>Dar-ul-Kitab</strong> are renewed automatically on a monthly or annual basis, depending on your chosen subscription plan. You can manage your subscription settings, including renewal preferences and payment methods, through your account settings.</li>
                            </ul>
                        </li>

                        <li><strong>Cancellation and Refunds:</strong>
                            <ul>
                                <li>You have the flexibility to cancel your subscription at any time. Upon cancellation, your access to <strong>Dar-ul-Kitab</strong> will continue until the end of your current billing period. We do not offer refunds for subscription periods.</li>
                            </ul>
                        </li>

                        <li><strong>Customer Support:</strong>
                            <ul>
                                <li>If you encounter any issues or have questions regarding your subscription or accessing our service, our customer support team is available to assist you. Please reach out to us via email at <a href="mailto:jamiasafiyaarbialilbanath@gmail.com">jamiasafiyaarbialilbanath@gmail.com</a></li>
                            </ul>
                        </li>

                        <li><strong>Updates and Enhancements:</strong>
                            <ul>
                                <li>We are committed to continuously improving <strong>Dar-ul-Kitab</strong> to enhance your streaming experience. This includes regularly updating our catalog, introducing new features, and optimizing performance across all platforms.</li>
                            </ul>
                        </li>

                        <li><strong>Privacy and Security:</strong>
                            <ul>
                                <li>Your privacy and security are of utmost importance to us. We adhere to strict data protection policies and employ advanced encryption methods to safeguard your personal information and ensure secure transactions.</li>
                            </ul>
                        </li>

                        <li><strong>Feedback and Suggestions:</strong>
                            <ul>
                                <li>We value your feedback and suggestions for improving <strong>Dar-ul-Kitab</strong>. If you have any ideas or recommendations, please feel free to share them with us. Your input helps us make <strong>Dar-ul-Kitab</strong> even better for our users.</li>
                            </ul>
                        </li>
                    </ol>

                    <p>By using <strong>Dar-ul-Kitab</strong>, you agree to abide by the terms outlined in this shipping and delivery policy. We reserve the right to update or modify this policy at any time, so we encourage you to review it periodically for any changes.</p>

                    <p>Thank you for choosing <strong>Dar-ul-Kitab</strong>! We hope you enjoy the ultimate streaming experience.</p>


                </div>
            </div>
        </div>
        <Footer />
    </>);
};

export default Shipping;