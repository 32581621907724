import React from "react";
import Footer from "../components/Footer";

function Contact() {
    return(<>
        <div className="col-lg-12 text-center">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 my-3">DAR-UL-KITAB</h1>
            <p className="lead">8-1-398/PM/207, Paramount Colony, Gate No 3, Opp IPS School, Tolichowki, Hyderabad 500008.</p>
            <p className="py-0">Mobile No: <a className="text-decoration-none text-black" href="tel:+919885687670">+919885687670</a></p>
            <p className="py-0">Email Id: jamiasafiyaarbialilbanath@gmail.com</p>
        </div>
        <Footer />
    </>);
};

export default Contact;