import React from "react";
//import { useAuth0 } from "@auth0/auth0-react";

function Subscribe() {
    //const {  user  } = useAuth0();
    return(<>
    
        {/* <h3 className="mt-5 text-center">Please Subscribe to listen Dars-e-Quraan</h3> */}
        {/* <h6 className="mt-3 text-center">Your email: <mark className="rounded-2 p-2"> { user.email } </mark></h6> */}
        {/* <div className="text-center"><a href="https://buy.stripe.com/28o7tq19e8pQg6Y8wy" className="btn btn-secondary btn-sm mt-3">Subscribe</a></div> */}
        {/* <form action="/data/post" method="POST">
            <label htmlFor="email" className="text-center">Your email: { user.email }</label>

            <input className="form-control mb-3" />
            
        </form> */}
        {/* <p className="mt-5 text-center">Use above mentioned email ID you used to login to this application at the time of checkout.  Giving any other email ids may not authorize you to view the content even the payment is success and Darulkitab is not responsible for this and you can not get any refunds for this discrepancy</p> */}

        <div class="container mt-4">
            <div class="card text-center">
                <div class="card-body">
                    <h5 class="card-title">Important Update</h5>
                    <p class="card-text">
                        Thank you for your interest in Dars-e-Quraan. Our app is currently unavailable, but we will be back shortly. Please stay tuned for updates. We apologize for any inconvenience and appreciate your patience.
                    </p>
                    <p class="card-text text-bg-warning rounded p-1">
                        Note: For users with a valid subscription, we have emailed the download link for the content to your subscription email id. This link will be available until November 30, 2024. Thank you for your continued support
                    </p>
                </div>
            </div>
        </div>

        
    </>);
};

export default Subscribe;