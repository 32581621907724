import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AudioList from "./AudioList";
import Player from "./Player";

const Hero = () => {

  const [selectedAudio, setSelectedAudio] = useState(null);
  const [selectedFName, setSelectedFName] = useState(null);

  const handleAudioSelect = (audURL) => {
    setSelectedAudio(audURL);
  };

  const handleFileName = (fname) => {
    setSelectedFName(fname);
  };

  const {
    isAuthenticated,
  } = useAuth0();

  return (
    <div className="row">

      {isAuthenticated && (
        <div className="col-xl-12">
          <AudioList id="myTable" onAudioSelect={handleAudioSelect} onFileName={handleFileName} />
          <Player className="audio-center" selectedAudio={selectedAudio} selectedFName={selectedFName} />
        </div>
      )}

    </div>
  );
};

export default Hero;
