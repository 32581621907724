import React, { useState, useEffect, useCallback } from "react";
import { Alert } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import Hero from "../components/Hero";
import Subscribe from "./Subscribe";


export const AuthrizedContent = () => {
  //const { apiOrigin = "https://darulkitab.in", audience } = getConfig();
  //const { apiOrigin = "https://darulkitab.s3-website-us-east-1.amazonaws.com", audience } = getConfig();
  const { apiOrigin = "https://protected-bastion-59726-2ca4708045e4.herokuapp.com", audience } = getConfig();
  //const { apiOrigin = "http://localhost:3001", audience } = getConfig();

  const {  user, isAuthenticated  } = useAuth0();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
    loading: false,
  });

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = useCallback(
    async () => {
      try {
        setState(prevState => ({
          ...prevState,
          loading: true, // Set loading to true while waiting for the API call
        }));
  
        const token = await getAccessTokenSilently();
        const userEmail = user.email;
        console.log(userEmail);
  
        const response = await fetch(`${apiOrigin}/api/external`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const responseData = await response.json();
        const subStatus = responseData.hasActiveSubscription;
        console.log(subStatus);
  
        setState(prevState => ({
          ...prevState,
          loading: false, // Set loading to false when the API call is complete
          showResult: true,
          apiMessage: responseData,
          subStatus: subStatus,
        }));
      } catch (error) {
        console.error(error); // Log the error for debugging
        setState(prevState => ({
          ...prevState,
          loading: false, // Set loading to false when an error occurs
          error: error.error,
        }));
      }
    },
    [getAccessTokenSilently, apiOrigin, user.email, setState]
  );
  

  // const callApi = useCallback(
  //   async () => {
  //     try {
  //       setState(prevState => ({
  //         ...prevState,
  //         loading: true, // Set loading to true while waiting for the API call
  //       }));
  
  //       const token = await getAccessTokenSilently();
  //       const userEmail = user.email;
  //       console.log(userEmail);
  //       const response = await fetch(`${apiOrigin}/api/external`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
          
  //       });
  
  //       const responseData = await response.json();
  //       const subStatus = responseData.hasActiveSubscription;
  //       console.log(subStatus);
  
  //       setState(prevState => ({
  //         ...prevState,
  //         showResult: true,
  //         apiMessage: responseData,
  //         subStatus: subStatus,
  //       }));
  //     } catch (error) {
  //       setState(prevState => ({
  //         ...prevState,
  //         error: error.error,
  //       }));
  //     }
  //   },
  //   [getAccessTokenSilently, apiOrigin, user.email, setState]
  // );
    


  useEffect(() => {
    // Call API when the user is authenticated
    if (isAuthenticated) {
      callApi();
    }
  }, [isAuthenticated, callApi]);

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  
  

  return (
    <>
      <div className="">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              className="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              className="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}

        {/* <h1>External API</h1> */}
        

        {!audience && (
          <Alert color="warning">
            <p>No audience</p>
          </Alert>
        )}

      </div>

      <div className="result-block-container">
        {state.loading ? (
          <Loading />
        ) : state.showResult && state.subStatus ? (
          <Hero />
        ) : (
          <Subscribe />
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(AuthrizedContent, {
  onRedirecting: () => <Loading />,
});
